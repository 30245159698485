import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Match } from "../types";
import { getAllMatches } from "../apis";
import { Emoji } from "../components/emoji.component";

type MatchesListPageProps = {};

export const MatchesListPage = ({ ...props }: MatchesListPageProps) => {
  const navigate = useNavigate();

  const [matches, setMatches] = useState<Match[]>([]);

  useEffect(() => {
    getAllMatches().then(setMatches);
  }, []);

  return (
    <>
      {matches.map((match) => (
        <>
          <Card
            _hover={{
              color: "teal.500",
              cursor: "pointer",
              transform: "scale(1.02)",
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/match/${match.matchId}`);
            }}
          >
            <CardBody>
              <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                <Image
                  borderRadius="full"
                  boxSize="20px"
                  src="/agesci_icon.png"
                  marginRight={"10px"}
                />
                <Text>{match.aTeamName}</Text>
                <Text marginLeft={"10px"} fontSize={"xs"}>
                  {match.aGroupName}
                </Text>
              </Flex>
              <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                <Image
                  borderRadius="full"
                  boxSize="20px"
                  src="/agesci_icon.png"
                  marginRight={"10px"}
                />
                <Text>{match.bTeamName}</Text>
                <Text marginLeft={"5px"} fontSize={"xs"}>
                  {match.bGroupName}
                </Text>
              </Flex>

              <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                marginTop={"10px"}
              >
                <Badge rounded={"lg"} fontSize="0.7em">
                  <Text>
                    <Emoji symbol={"⏰‍"} /> {match.time}
                  </Text>
                </Badge>
                <Badge rounded={"lg"} fontSize="0.7em">
                  <Text>
                    <Emoji symbol={"📍"} /> {match.field}
                  </Text>
                </Badge>
              </Flex>
              <Button
                colorScheme="teal"
                size="xs"
                width={"100%"}
                marginTop={"5px"}
              >
                Inizia ad arbitrare
              </Button>
            </CardBody>
          </Card>
          <Box height={"10px"} />
        </>
      ))}
    </>
  );
};
