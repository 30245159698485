import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import moment from "moment/moment";
import React from "react";

export const EndMatchModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Conferma fine partita</ModalHeader>
        <ModalBody pb={6}>
          <Text fontSize="lg">
            <b>La partita è finita in questo momento?</b>
          </Text>
          <Text fontSize="md">
            Non è possibile far finire una partita due volte.
          </Text>

          <Alert status="info" marginTop={"30px"}>
            <AlertIcon />
            Verrà registrato che la partita è finite alle
            {moment().format(" HH:mm")}. Non sarà possibile più variare ne gli
            eventi (mete e falli) ne il risultato.
          </Alert>

          <Text fontSize="sm"></Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3}>
            Termina partita
          </Button>
          <Button onClick={onClose}>Annulla</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
