import React from 'react';
import './App.css';
import {Box, ChakraProvider} from '@chakra-ui/react';
import {MatchDetailPage} from "./pages/match-detail.page";
import {Route, Routes} from 'react-router-dom';
import {MatchesListPage} from "./pages/matches-list.page";


function App() {
    return (<>
            <ChakraProvider>
                <Box padding={'5%'}>
                    <Routes>
                        <Route index element={<MatchesListPage/>}/>
                        <Route path="match/*" element={<MatchDetailPage/>}/>
                    </Routes>
                </Box>
            </ChakraProvider>

        </>
    );
}

export default App;
