import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import moment from "moment/moment";
import React from "react";

export const StartMatchModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Conferma inizio partita</ModalHeader>
        <ModalBody pb={6}>
          <Text fontSize="lg">
            <b>La partita sta effettivamente iniziando in questo momento?</b>
          </Text>
          <Text fontSize="md">
            Non è possibile far iniziare una partita due volte.
          </Text>

          <Alert status="info" marginTop={"30px"}>
            <AlertIcon />
            Verrà registrato che la partita è iniziata alle
            {moment().format(" HH:mm")}. Tutti gli eventi della partita (mete e
            falli) saranno registrati con in relazione a questo orario di inizio
          </Alert>

          <Text fontSize="sm"></Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3}>
            Inizia partita
          </Button>
          <Button onClick={onClose}>Annulla</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
