import { Match } from "../types";
import { MatchEvent } from "../types/match-event.type";
import axios from "axios";

export const getAllMatches = async (): Promise<Match[]> => {
  const resp = await axios.get(
    `https://sheets2json.gtoselli.workers.dev/1zan_tp8pVtpTip2Hu5A_RSpaL5fPRX4ugoFpId_SL2M/matches`
  );
  return resp.data;
};

export const getMatchEvents = async (
  matchId: string
): Promise<MatchEvent[]> => {
  const events: MatchEvent[] = [
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
    {
      type: "meta",
      playerNumber: "1",
      teamName: "Ciaofiore",
    },
    {
      type: "fallo",
      playerNumber: "10",
      teamName: "Lukaku is the best",
    },
    {
      type: "fallo",
      playerNumber: "5",
      teamName: "Ciaofiore",
    },
  ];
  return events;
};
