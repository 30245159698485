import { Match } from "../types";
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Emoji } from "../components/emoji.component";
import { CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { MatchEvent } from "../types/match-event.type";
import { getMatchEvents } from "../apis";
import { StartMatchModal } from "../components/modals/start-match-modal";
import moment from "moment";
import { EndMatchModal } from "../components/modals/end-match-modal";

type MatchDetailPageProps = {};
export const MatchDetailPage = ({ ...props }: MatchDetailPageProps) => {
  const match: Match = {
    matchId: "7f853467-f48d-418a-bcc5-5ff9d6c0768a",
    aGroupName: "Cinisello Balsamo 3",
    aTeamName: "FSK",
    bGroupName: "Cassina de Pecchi 10",
    bTeamName: "Paulaster",
    time: "",
    field: "",
  };

  const [events, setEvents] = useState<MatchEvent[]>([]);

  const {
    isOpen: startMatchModalIsOpen,
    onOpen: onOpenStartMatchModal,
    onClose: onCloseStartMatchModal,
  } = useDisclosure();

  const {
    isOpen: endMatchModalIsOpen,
    onOpen: onOpenEndMatchModal,
    onClose: onCloseEndMatchModal,
  } = useDisclosure();

  useEffect(() => {
    getMatchEvents(match.matchId).then(setEvents);
  }, [match.matchId]);

  return (
    <>
      <Card>
        <CardBody>
          <Center>
            <Heading size="sm" color={"grey"}>
              {match.aTeamName} vs {match.bTeamName}
            </Heading>
          </Center>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Heading size="md" colorScheme={"grey"}>
              10
            </Heading>
            <Heading size="md" colorScheme={"grey"}>
              10
            </Heading>
          </Flex>
        </CardBody>
      </Card>
      <Button
        leftIcon={<CheckIcon />}
        colorScheme="teal"
        variant="outline"
        size="sm"
        width={"100%"}
        marginTop={"10px"}
        onClick={
          Number(moment().format("ss")) % 2
            ? onOpenStartMatchModal
            : onOpenEndMatchModal
        }
      >
        Inizia / Termina
      </Button>
      <SimpleGrid columns={2} spacing={4} paddingTop={"10px"}>
        <Flex
          flexDirection={"row"}
          width={"100%"}
          alignItems={"center"}
          borderWidth="1px"
          borderRadius="lg"
          padding={"5px"}
          borderColor={"teal"}
          height={"32px"}
        >
          <Spacer />
          <Image
            borderRadius="full"
            boxSize="25px"
            src="/rugby-player.png"
            marginRight={"10px"}
          />
          <Text>Meta</Text>
          <Spacer />
        </Flex>
        <Flex
          flexDirection={"row"}
          width={"100%"}
          alignItems={"center"}
          borderWidth="1px"
          borderRadius="lg"
          padding={"5px"}
          borderColor={"teal"}
          height={"32px"}
        >
          <Spacer />
          <Image
            borderRadius="full"
            boxSize="20px"
            src="/red-card.png"
            marginRight={"10px"}
          />
          <Text>Fallo</Text>
          <Spacer />
        </Flex>
      </SimpleGrid>
      <Box display={"flex"} alignItems="center" flexDirection={"column"}>
        {events.map((event) => (
          <Box
            width={"100%"}
            marginTop={"20px"}
            borderWidth="1px"
            borderRadius="lg"
            padding={"5px"}
            flexDirection={"row"}
            display={"flex"}
          >
            <Box width={"85%"} height={"70px"}>
              <Heading size={"md"}>{event.type}</Heading>
              <Text>
                <Emoji symbol={"🤾🏼‍"} /> maglia {event.playerNumber}
              </Text>
              <Text>
                <Emoji symbol={"🕦"} /> 2"
              </Text>
            </Box>
            <Box
              width={"15%"}
              height={"70px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <DeleteIcon height={"100%"} color={"red"} />
            </Box>
          </Box>
        ))}
      </Box>
      <StartMatchModal
        isOpen={startMatchModalIsOpen}
        onClose={onCloseStartMatchModal}
      />
      <EndMatchModal
        isOpen={endMatchModalIsOpen}
        onClose={onCloseEndMatchModal}
      />
    </>
  );
};
